import React, { useState, useEffect } from "react";
import { useSubheader } from "../../_metronic/layout";
import MaterialTable from "material-table";
import axios from "axios";
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
// eslint-disable-next-line no-restricted-imports
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import Select from "react-select";
import XLSX from "xlsx";

const useStyles = makeStyles((theme) => ({
  editIcon: {
    color: "orange",
  },
  deleteIcon: {
    color: "red",
  },
}));

export const BudgetPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Budget");

  const userProfile = JSON.parse(localStorage.getItem("userProfile"))
    ?.role_code;

  const classes = useStyles();

  const customstyles = {
    control: (provided, state) => ({
      ...provided,
      paddingLeft: 5,
      height: 35,
      width: 250,
      marginRight: 10,
      marginBottom: 10,
    }),
  };

  const tableOptions = {
    actionsColumnIndex: 9, // Set the index of the actions column
    toolbarButtonAlignment: "left", // Align the toolbar buttons to the left
    paging: true,
    pageSize: 20, // make initial page size
    emptyRowsWhenPaging: false, // To avoid of having empty rows
    pageSizeOptions: [20, 50, 100, 200], // rows selection options
    search: false,
    headerStyle: {
      zIndex: 0,
    },
  };

  const [loading, setLoading] = useState(false);
  const [loadingdelete, setLoadingDelete] = useState(false);
  const [loadingedit, setLoadingEdit] = useState(false);
  const [datamasterbudget, setDataMasterBudget] = useState([]);
  const [databudget, setDataBudget] = useState([]);
  const [modaldelete, setModalDelete] = useState(false);
  const [modaledit, setModalEdit] = useState(false);
  const [mockdelete, setMockDelete] = useState({});
  const [mockedit, setMockEdit] = useState({});
  const [budgetrequestedit, setBudgetRequestEdit] = useState(0);
  const [budgetapproveedit, setBudgetApproveEdit] = useState(0);
  const [department, setDepartment] = useState("");
  const [typebudget, setTypeBudget] = useState("");
  const [yearmonth, setYearMonth] = useState("");
  const [startsortyearmonth, setStartSortYearMonth] = useState({});
  const [endsortyearmonth, setEndSortYearMonth] = useState({});
  const [optionsdepartment, setOptionsDepartment] = useState([]);
  const [optionstypebudget, setOptionsTypeBudget] = useState([]);
  const [optionsyearmonth, setOptionsYearMonth] = useState([]);
  const [optionsortyearmonth, setOptionSortYearMonth] = useState([]);
  const [datasearch, setDataSearch] = useState("");
  const [loadingexport, setLoadingExport] = useState(false);

  const [typebudgetsearch, setTypeBudgetSearch] = useState({
    value: "EP",
    label: "Expense",
  });

  const getFormattedDateTime = () => {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, "0");
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = now.getFullYear();
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");
    return `${day}-${month}-${year}-${hours}${minutes}${seconds}`;
  };

  const clickExportFile = async () => {
    try {
      if (startsortyearmonth?.length < 1) {
        return toast.error("start yearmonth is required!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (endsortyearmonth?.length < 1) {
        return toast.error("end yearmonth is required!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      setLoadingExport(true);
      const { data } = await axios.post("budget/export", {
        from: startsortyearmonth?.value,
        to: endsortyearmonth?.value,
      });
      if (data?.message?.length > 0) {
        let data_new = [];

        // eslint-disable-next-line no-unused-vars
        for await (const [_, item] of databudget.entries()) {
          data_new.push({
            year_and_month: item?.year_and_month,
            acc_code: item?.acc_code,
            type_budget: item?.type_budget,
            budget_detail: item?.budget_detail,
            department: item?.department,
            budget_request:
              item?.budget_request !== "" && item?.budget_request !== null
                ? parseFloat(item?.budget_request).toFixed(2)
                : item?.budget_request,
            budget_approve:
              item?.budget_approve !== "" && item?.budget_approve !== null
                ? parseFloat(item?.budget_approve).toFixed(2)
                : item?.budget_approve,
            Remark: "",
          });
        }

        const dataWS = XLSX.utils.json_to_sheet(data_new, {
          header: [
            "year_and_month",
            "acc_code",
            "type_budget",
            "budget_detail",
            "department",
            "budget_request",
            "budget_approve",
            "Remark",
          ],
        });
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, dataWS, "Budget");
        XLSX.writeFile(wb, `budget_${getFormattedDateTime()}.xlsx`);

        await getBudget();
        toast.success("Export Success!", {
          position: "top-center",
          autoClose: 3000,
        });
        setLoadingExport(false);
      }
    } catch (error) {
      setLoadingExport(false);
      return toast.error(error?.response?.data?.error?.message, {
        position: "top-center",
        autoClose: 3000,
      });
    }
  };

  const clickEdit = async (data) => {
    try {
      if (optionsdepartment?.length > 0) {
        const dataDepartment = optionsdepartment?.filter(
          (x) => x?.value === data?.department
        );
        if (dataDepartment?.length > 0) {
          setDepartment(dataDepartment[0]);
        }
      }
      if (optionstypebudget?.length > 0) {
        const dataTypeBudget = optionstypebudget?.filter(
          (x) => x?.label === data?.type_budget
        );
        if (dataTypeBudget?.length > 0) {
          setTypeBudget(dataTypeBudget[0]);
        }
      }
      if (optionsyearmonth?.length > 0) {
        const dataYearMonth = optionsyearmonth?.filter(
          (x) => x?.value === data?.year_and_month
        );
        if (dataYearMonth?.length > 0) {
          setYearMonth(dataYearMonth[0]);
        }
      }
      setMockEdit(data);
      setBudgetRequestEdit(convertFloatToString(data?.budget_request));
      setBudgetApproveEdit(convertFloatToString(data?.budget_approve));
      setModalEdit(true);
    } catch (error) {
      console.log(error);
    }
  };

  const clickDelete = (data) => {
    try {
      setMockDelete(data);
      setModalDelete(true);
    } catch (error) {
      console.log(error);
    }
  };

  const addComma = (x) => {
    try {
      // ตรวจสอบว่าค่าที่ใส่เข้ามาเป็นตัวเลขหรือไม่
      if (isNaN(x)) {
        throw new Error("Input is not a valid number");
      }
      // แปลงค่าเป็นตัวเลขทศนิยม 2 ตำแหน่ง
      let number = parseFloat(x).toFixed(2);
      // แยกส่วนทศนิยมและใส่เครื่องหมายคอมม่า
      let parts = number.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
    } catch (error) {
      console.log(error);
    }
  };

  const convertFloatToString = (x) => {
    try {
      const formattedString = x.toLocaleString();
      return formattedString;
    } catch (error) {
      console.log(error);
    }
  };

  const convertStringToFloat = (x) => {
    try {
      const numericValue = parseFloat(x.replace(/,/g, ""));
      return numericValue;
    } catch (error) {
      console.log(error);
    }
  };

  const changeStartYearMonth = (data) => {
    try {
      if (data?.value > endsortyearmonth?.value) {
        setEndSortYearMonth(data);
      }
      setStartSortYearMonth(data);
    } catch (error) {
      console.log(error);
    }
  };

  const changeEndYearMonth = (data) => {
    try {
      if (data?.value < startsortyearmonth?.value) {
        setStartSortYearMonth(data);
      }
      setEndSortYearMonth(data);
    } catch (error) {
      console.log(error);
    }
  };

  const clickConfirmEdit = async () => {
    try {
      if (budgetrequestedit === "" || budgetrequestedit === undefined) {
        return toast.error("Budget Request is required!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (budgetapproveedit === "" || budgetapproveedit === undefined) {
        return toast.error("Budget Approve is required!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      setLoadingEdit(true);
      const { data } = await axios.put(`budget/edit/${mockedit?.id}`, {
        budget_request: convertStringToFloat(budgetrequestedit),
        budget_approve: convertStringToFloat(budgetapproveedit),
      });
      if (data?.message?.length > 0) {
        setLoadingEdit(false);
        setModalEdit(false);
        await getBudget();
        toast.success("Edit Success!", {
          position: "top-center",
          autoClose: 3000,
        });
      } else {
        setLoadingEdit(false);
      }
    } catch (error) {
      setLoadingEdit(false);
      return toast.error(error?.response?.data?.error?.message, {
        position: "top-center",
        autoClose: 3000,
      });
    }
  };

  const clickConfirmDelete = async () => {
    try {
      setLoadingDelete(true);
      const { data } = await axios.delete(`budget/remove/${mockdelete?.id}`);
      if (data?.message?.length > 0) {
        setLoadingDelete(false);
        setModalDelete(false);
        await getBudget();
        toast.success("Delete Success!", {
          position: "top-center",
          autoClose: 3000,
        });
      } else {
        setLoadingDelete(false);
      }
    } catch (error) {
      setLoadingDelete(false);
      return toast.error(error?.response?.data?.error?.message, {
        position: "top-center",
        autoClose: 3000,
      });
    }
  };

  const changeBudgetRequest = (e) => {
    try {
      let inputValue = e.target.value;
      // Allow empty input or valid float pattern
      if (
        inputValue === "" ||
        /^-?\d*\.?\d*$/.test(inputValue.replace(/,/g, ""))
      ) {
        // Remove any existing commas before formatting
        inputValue = inputValue.replace(/,/g, "");
        // Split into integer and decimal parts
        const parts = inputValue.split(".");
        // Format the integer part with commas
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        // Join the integer and decimal parts
        const formattedValue = parts.join(".");
        setBudgetRequestEdit(formattedValue);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeBudgetApprove = (e) => {
    try {
      let inputValue = e.target.value;
      // Allow empty input or valid float pattern
      if (
        inputValue === "" ||
        /^-?\d*\.?\d*$/.test(inputValue.replace(/,/g, ""))
      ) {
        // Remove any existing commas before formatting
        inputValue = inputValue.replace(/,/g, "");
        // Split into integer and decimal parts
        const parts = inputValue.split(".");
        // Format the integer part with commas
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        // Join the integer and decimal parts
        const formattedValue = parts.join(".");
        setBudgetApproveEdit(formattedValue);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeTypeBudget = (data) => {
    try {
      setTypeBudgetSearch(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getBudget = async () => {
    try {
      setLoading(true);
      const resBudget = await axios.get("budget/all");
      if (resBudget?.data?.length > 0) {
        setDataMasterBudget(resBudget?.data);
        setDataBudget(resBudget?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getDepartment = async () => {
    try {
      const resDepartment = await axios.get("admin/department");
      if (resDepartment?.data?.length > 0) {
        let dataMock = [];
        // eslint-disable-next-line no-unused-expressions, array-callback-return
        await resDepartment?.data?.map((itme) => {
          dataMock.push({
            value: itme?.department_code,
            label: itme?.department_name,
          });
        });
        setOptionsDepartment(dataMock);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getTypeBudget = async () => {
    try {
      const resTypeBudget = await axios.get("budget/type");
      if (resTypeBudget?.data?.length > 0) {
        let dataMock = [];
        // eslint-disable-next-line no-unused-expressions, array-callback-return
        await resTypeBudget?.data?.map((itme) => {
          dataMock.push({
            value: itme?.Type_code,
            label: itme?.Type_name,
          });
        });
        setOptionsTypeBudget(dataMock);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getYearMonth = async () => {
    try {
      let yearMonthBefore = [];
      let yearMonthNow = [];
      let yearMonthNext = [];
      const yearBefore = new Date().getFullYear() - 1;
      const yearNow = new Date().getFullYear();
      const yearNext = new Date().getFullYear() + 1;
      const startMonth = 0; // January is 0 in JavaScript
      const endMonth = 11; // December is 11 in JavaScript

      for (let month = startMonth; month <= endMonth; month++) {
        const monthFormatted = (month + 1).toString().padStart(2, "0"); // Ensure month is two digits
        yearMonthBefore.push(`${yearBefore}-${monthFormatted}`);
        yearMonthNow.push(`${yearNow}-${monthFormatted}`);
        yearMonthNext.push(`${yearNext}-${monthFormatted}`);
      }
      const allYearMonth = yearMonthBefore.concat(yearMonthNow, yearMonthNext);
      if (allYearMonth?.length > 0) {
        let dataMock = [];
        // eslint-disable-next-line no-unused-expressions, array-callback-return
        allYearMonth?.map((itme) => {
          dataMock.push({
            value: itme,
            label: itme,
          });
        });
        if (dataMock?.length === allYearMonth?.length) {
          setOptionsYearMonth(dataMock);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSortYearMonth = async () => {
    try {
      const resYearMonth = await axios.get("budget/year-month");
      if (resYearMonth?.data?.length > 0) {
        let dataMock = [];
        // eslint-disable-next-line no-unused-expressions, array-callback-return
        await resYearMonth?.data?.map((itme) => {
          dataMock.push({
            value: itme,
            label: itme,
          });
        });
        setOptionSortYearMonth(dataMock);

        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const yearMonth = `${year}-${month}`;
        setStartSortYearMonth({
          value: yearMonth,
          label: yearMonth,
        });
        setEndSortYearMonth({
          value: yearMonth,
          label: yearMonth,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getYearMonth();
    getTypeBudget();
    getDepartment();
    getSortYearMonth();
    getBudget();
  }, []);

  const sortDataAll = async () => {
    try {
      setLoading(true);
      let master_temp = [];
      if (datamasterbudget?.length > 0) {
        master_temp = [...datamasterbudget];
      }

      if (
        startsortyearmonth?.value !== null &&
        startsortyearmonth?.value !== undefined &&
        endsortyearmonth?.value !== null &&
        endsortyearmonth?.value !== undefined
      ) {
        let data_same = [];
        // eslint-disable-next-line no-unused-vars
        for await (const [_, item] of master_temp.entries()) {
          if (
            item?.year_and_month !== undefined &&
            item?.year_and_month !== null
          ) {
            // Extract year and month as separate values for easier comparison
            const itemDate = item.year_and_month.toLowerCase();
            const startDate = startsortyearmonth?.value.toLowerCase();
            const endDate = endsortyearmonth?.value.toLowerCase();

            if (itemDate >= startDate && itemDate <= endDate) {
              data_same.push(item);
            }
          }
        }
        master_temp = data_same;
      }

      if (
        typebudgetsearch?.value !== null &&
        typebudgetsearch?.value !== undefined
      ) {
        let data_same = [];
        // eslint-disable-next-line no-unused-vars
        for await (const [_, item] of master_temp.entries()) {
          if (item?.type_budget !== undefined && item?.type_budget !== null) {
            if (
              item?.type_budget
                .toLowerCase()
                .includes(typebudgetsearch?.label.toLowerCase()) > 0
            ) {
              data_same.push(item);
            }
          }
        }
        master_temp = data_same;
      }

      if (datasearch?.length > 0) {
        let data_same = [];
        // eslint-disable-next-line no-unused-vars
        for await (const [_, item] of master_temp.entries()) {
          if (item?.acc_code !== undefined && item?.acc_code !== null) {
            if (
              item?.acc_code.toLowerCase().includes(datasearch.toLowerCase()) >
              0
            ) {
              data_same.push(item);
            }
          }

          if (
            item?.budget_detail !== undefined &&
            item?.budget_detail !== null
          ) {
            if (
              item?.budget_detail
                .toLowerCase()
                .includes(datasearch.toLowerCase()) > 0
            ) {
              data_same.push(item);
            }
          }

          if (item?.type_budget !== undefined && item?.type_budget !== null) {
            if (
              item?.type_budget
                .toLowerCase()
                .includes(datasearch.toLowerCase()) > 0
            ) {
              data_same.push(item);
            }
          }

          if (item?.department !== undefined && item?.department !== null) {
            if (
              item?.department
                .toLowerCase()
                .includes(datasearch.toLowerCase()) > 0
            ) {
              data_same.push(item);
            }
          }

          if (item?.type_budget !== undefined && item?.type_budget !== null) {
            if (
              item?.type_budget
                .toLowerCase()
                .includes(datasearch.toLowerCase()) > 0
            ) {
              data_same.push(item);
            }
          }

          if (
            item?.year_and_month !== undefined &&
            item?.year_and_month !== null
          ) {
            if (
              item?.year_and_month
                .toLowerCase()
                .includes(datasearch.toLowerCase()) > 0
            ) {
              data_same.push(item);
            }
          }

          if (
            item?.budget_request !== undefined &&
            item?.budget_request !== null
          ) {
            if (
              item?.budget_request
                .toString()
                .toLowerCase()
                .includes(datasearch.toLowerCase()) > 0
            ) {
              data_same.push(item);
            }
          }

          if (
            item?.budget_approve !== undefined &&
            item?.budget_approve !== null
          ) {
            if (
              item?.budget_approve
                .toString()
                .toLowerCase()
                .includes(datasearch.toLowerCase()) > 0
            ) {
              data_same.push(item);
            }
          }

          if (item?.status !== undefined && item?.status !== null) {
            if (
              item?.status.toLowerCase().includes(datasearch.toLowerCase()) > 0
            ) {
              data_same.push(item);
            }
          }
        }
        master_temp = data_same;
      }

      const uniqueData = master_temp.filter((obj, index, self) => {
        return (
          index ===
          self.findIndex(
            (item) => item?.id === obj?.id // Change 'name' to the desired property
          )
        );
      });
      setDataBudget(uniqueData);
      setLoading(false);

      if (
        startsortyearmonth === null &&
        endsortyearmonth === null &&
        datasearch === "" &&
        typebudgetsearch?.value === undefined
      ) {
        setDataBudget(datamasterbudget);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    sortDataAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    datamasterbudget,
    startsortyearmonth,
    endsortyearmonth,
    typebudgetsearch,
    datasearch,
  ]);

  return (
    <div
      style={{
        paddingTop: 15,
        paddingBottom: 30,
      }}
    >
      <div className="container-budget-4">
        <div className="container-budget-6">
          <label htmlFor="StartMonth" style={{ marginRight: 10 }}>
            Month
          </label>
          <Select
            value={startsortyearmonth}
            styles={customstyles}
            options={optionsortyearmonth}
            onChange={changeStartYearMonth}
            isSearchable={true}
            placeholder="Start Year And Month"
            isClearable={startsortyearmonth?.value?.length > 0 ? true : false}
          />
        </div>
        <div className="container-budget-6">
          <label htmlFor="EndMonth" style={{ marginRight: 10 }}>
            To
          </label>
          <Select
            value={endsortyearmonth}
            styles={customstyles}
            options={optionsortyearmonth}
            onChange={changeEndYearMonth}
            isSearchable={true}
            placeholder="End Year And Month"
            isClearable={endsortyearmonth?.value?.length > 0 ? true : false}
          />
        </div>
        <div className="container-budget-6">
          <label htmlFor="Type" style={{ marginRight: 10 }}>
            Type
          </label>
          <Select
            value={typebudgetsearch}
            styles={customstyles}
            options={optionstypebudget}
            onChange={changeTypeBudget}
            isSearchable={true}
            placeholder="Type Budget"
            isClearable={typebudgetsearch?.value?.length > 0 ? true : false}
          />
        </div>
        <div
          className="form-group"
          style={{ width: 250, marginRight: 10, marginBottom: 10 }}
        >
          <div className="input-icon input-icon-right">
            <input
              type="text"
              className="form-control"
              placeholder="Search..."
              value={datasearch}
              onChange={(e) => setDataSearch(e.target.value)}
            />
            <span>
              <i className="flaticon2-search-1 icon-md"></i>
            </span>
          </div>
        </div>
        {(userProfile === "98" || userProfile === "99") && (
          <button
            className="container-budget-5"
            onClick={clickExportFile}
            disabled={
              databudget?.length > 0 && loadingexport === false ? false : true
            }
          >
            Export
          </button>
        )}
      </div>
      <MaterialTable
        title="List Budget"
        isLoading={loading}
        localization={{
          body: {
            emptyDataSourceMessage: "No Data",
          },
          toolbar: {
            searchPlaceholder: "Search...",
          },
          pagination: {
            labelRowsSelect: "Row",
            firstTooltip: "First",
            previousTooltip: "Previous",
            nextTooltip: "Next",
            lastTooltip: "Last",
          },
          header: {
            actions: "Action",
          },
        }}
        columns={[
          {
            title: "Account",
            field: "acc_code",
            render: (rowData) => {
              return <div style={{ width: 100 }}>{rowData?.acc_code}</div>;
            },
          },
          {
            title: "Detail",
            field: "budget_detail",
            render: (rowData) => {
              return <div style={{ width: 250 }}>{rowData?.budget_detail}</div>;
            },
          },
          {
            title: "Type Budget",
            field: "type_budget",
            render: (rowData) => {
              return <div style={{ width: 100 }}>{rowData?.type_budget}</div>;
            },
          },
          {
            title: "Department",
            field: "department",
            render: (rowData) => {
              return <div style={{ width: 120 }}>{rowData?.department}</div>;
            },
          },
          {
            title: "Type Budget",
            field: "type_budget",
            render: (rowData) => {
              return <div style={{ width: 120 }}>{rowData?.type_budget}</div>;
            },
          },
          {
            title: "Year And Month",
            field: "year_and_month",
            render: (rowData) => {
              return (
                <div style={{ width: 120 }}>{rowData?.year_and_month}</div>
              );
            },
          },
          {
            title: "Budget Request",
            field: "budget_request",
            render: (rowData) => {
              return (
                <div style={{ width: 120 }}>
                  {addComma(rowData?.budget_request)}
                </div>
              );
            },
          },
          {
            title: "Budget Approve",
            field: "budget_approve",
            render: (rowData) => {
              return (
                <div style={{ width: 120 }}>
                  {addComma(rowData?.budget_approve)}
                </div>
              );
            },
          },
          {
            title: "Status",
            field: "status",
            render: (rowData) => {
              return (
                <span className="label label-success label-lg font-weight-bold label-inline">
                  {rowData?.status}
                </span>
              );
            },
          },
        ]}
        data={databudget}
        actions={[
          {
            icon: () => <EditIcon className={classes.editIcon} />,
            tooltip: "Edit",
            onClick: (event, rowData) =>
              rowData?.status === "approve" || rowData?.status === "export"
                ? userProfile === "98" || userProfile === "99"
                  ? clickEdit(rowData)
                  : null
                : clickEdit(rowData),
          },
          {
            icon: () => <DeleteIcon className={classes.deleteIcon} />,
            tooltip: "Delete",
            onClick: (event, rowData) =>
              rowData?.status === "approve" || rowData?.status === "export"
                ? userProfile === "98" || userProfile === "99"
                  ? clickDelete(rowData)
                  : null
                : clickDelete(rowData),
          },
        ]}
        options={tableOptions}
      />
      {/* start modal edit */}
      <Modal
        show={modaledit}
        onHide={() => setModalEdit(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div className="container-budget-3">
            <div className="form-group">
              <label htmlFor="Account">Account</label>
              <input
                type="text"
                className="form-control"
                placeholder="Account"
                name="Account"
                value={mockedit?.acc_code}
                disabled
              />
            </div>
            <div className="form-group">
              <label htmlFor="Department">Department</label>
              <input
                type="text"
                className="form-control"
                placeholder="Department"
                name="Department"
                value={department?.label}
                disabled
              />
            </div>
            <div className="form-group">
              <label htmlFor="TypeBudget">Type Budget</label>
              <input
                type="text"
                className="form-control"
                placeholder="TypeBudget"
                name="TypeBudget"
                value={typebudget?.label}
                disabled
              />
            </div>
            <div className="form-group">
              <label htmlFor="Detail">Detail</label>
              <input
                type="text"
                className="form-control"
                placeholder="Detail"
                name="Detail"
                value={mockedit?.budget_detail}
                disabled
              />
            </div>
            <div className="form-group">
              <label htmlFor="YearAndMonth">Year And Month</label>
              <input
                type="text"
                className="form-control"
                placeholder="YearAndMonth"
                name="YearAndMonth"
                value={yearmonth?.label}
                disabled
              />
            </div>
            <div className="form-group">
              <label htmlFor="BudgetRequest">Budget Request</label>
              <input
                type="text"
                className="form-control"
                placeholder="Budget Request"
                name="BudgetRequest"
                maxLength={14}
                value={budgetrequestedit}
                onChange={changeBudgetRequest}
              />
            </div>
            <div className="form-group">
              <label htmlFor="BudgetApprove">Budget Approve</label>
              <input
                type="text"
                className="form-control"
                placeholder="Budget Approve"
                name="BudgetApprove"
                maxLength={14}
                value={budgetapproveedit}
                onChange={changeBudgetApprove}
                disabled={
                  userProfile === "98" || userProfile === "99" ? false : true
                }
              />
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                type="submit"
                disabled={loadingedit}
                className="btn btn-primary font-weight-bold px-9 py-4 mx-4"
                onClick={clickConfirmEdit}
              >
                <span>Submit</span>
                {loadingedit && (
                  <span className="ml-3 spinner spinner-white"></span>
                )}
              </button>
              <button
                type="button"
                className="btn btn-light-danger font-weight-bold px-9 py-4 mx-4"
                onClick={() => setModalEdit(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* end modal edit */}
      {/* start modal delete */}
      <Modal
        show={modaldelete}
        onHide={() => setModalDelete(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div className="container-budget-2">
            <div className="text-budget-1">Do you want to delete it?</div>
            <div className="form-group d-flex flex-wrap flex-center mt-5">
              <button
                type="submit"
                disabled={loadingdelete}
                className="btn btn-primary font-weight-bold px-9 py-4 mx-4"
                onClick={clickConfirmDelete}
              >
                <span>Submit</span>
                {loadingdelete && (
                  <span className="ml-3 spinner spinner-white"></span>
                )}
              </button>
              <button
                type="button"
                className="btn btn-light-danger font-weight-bold px-9 py-4 mx-4"
                onClick={() => setModalDelete(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* end modal delete */}
    </div>
  );
};
