import React, { useState, useEffect } from "react";
import { useSubheader } from "../../_metronic/layout";
import MaterialTable from "material-table";
import axios from "axios";
import Select from "react-select";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";

export const BudgetSalesPage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Budget Sales");

  const userProfile = JSON.parse(localStorage.getItem("userProfile"))
    ?.role_code;

  const [loading, setLoading] = useState(false);
  const [loadingimport, setLoadingImport] = useState(false);
  const [databudgetsales, setDataBudgetSales] = useState([]);
  const [datamasterbudgetsales, setDataMasterBudgetSales] = useState([]);
  const [startsortyearmonth, setStartSortYearMonth] = useState({});
  const [endsortyearmonth, setEndSortYearMonth] = useState({});
  const [optionsortyearmonth, setOptionSortYearMonth] = useState([]);
  const [datasearch, setDataSearch] = useState("");

  const tableOptions = {
    actionsColumnIndex: 8, // Set the index of the actions column
    toolbarButtonAlignment: "left", // Align the toolbar buttons to the left
    paging: true,
    pageSize: 20, // make initial page size
    emptyRowsWhenPaging: false, // To avoid of having empty rows
    pageSizeOptions: [20, 50, 100, 200], // rows selection options
    search: false,
    headerStyle: {
      zIndex: 0,
    },
  };

  const customstyles = {
    control: (provided, state) => ({
      ...provided,
      paddingLeft: 5,
      height: 35,
      width: 250,
      marginRight: 10,
      marginBottom: 10,
    }),
  };

  const handleFileUpload = (event) => {
    try {
      setLoadingImport(true);
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          let jsonData = XLSX.utils.sheet_to_json(worksheet);

          if (jsonData?.length > 0) {
            const { data } = await axios.post("budget-sales/import", {
              data: jsonData,
            });
            if (data?.message?.length > 0) {
              toast.success("Import Success!", {
                position: "top-center",
                autoClose: 3000,
              });
              await getBudgetSales();
            }
          }
        };
        reader.readAsArrayBuffer(file);
        // Reset the file input value to allow repeated uploads
        event.target.value = "";
        setLoadingImport(false);
      }
    } catch (error) {
      setLoadingImport(false);
      return toast.error(error?.response?.data?.error?.message, {
        position: "top-center",
        autoClose: 3000,
      });
    }
  };

  const getFormattedDateTime = () => {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, "0");
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = now.getFullYear();
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");
    return `${day}-${month}-${year}-${hours}${minutes}${seconds}`;
  };

  const clickExportFile = () => {
    try {
      let data_new = [
        {
          year_and_month: "2024-09",
          brand: "HTR",
          brand_code: "SHA014",
          brand_name: "SHA014-CT Rama9",
          value: 2000000.0,
        },
        {
          year_and_month: "2024-10",
          brand: "HTR",
          brand_code: "SHA014",
          brand_name: "SHA014-CT Rama9",
          value: 2000000.0,
        },
      ];
      const dataWS = XLSX.utils.json_to_sheet(data_new, {
        header: [
          "year_and_month",
          "brand",
          "brand_code",
          "brand_name",
          "value",
        ],
      });
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataWS, "ImportBudgetSales");
      XLSX.writeFile(wb, `importbudgetsales_${getFormattedDateTime()}.xlsx`);
    } catch (error) {
      console.log(error);
    }
  };

  const addComma = (x) => {
    try {
      // ตรวจสอบว่าค่าที่ใส่เข้ามาเป็นตัวเลขหรือไม่
      if (isNaN(x)) {
        throw new Error("Input is not a valid number");
      }
      // แปลงค่าเป็นตัวเลขทศนิยม 2 ตำแหน่ง
      let number = parseFloat(x).toFixed(2);
      // แยกส่วนทศนิยมและใส่เครื่องหมายคอมม่า
      let parts = number.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
    } catch (error) {
      console.log(error);
    }
  };

  const changeStartYearMonth = (data) => {
    try {
      if (data?.value > endsortyearmonth?.value) {
        setEndSortYearMonth(data);
      }
      setStartSortYearMonth(data);
    } catch (error) {
      console.log(error);
    }
  };

  const changeEndYearMonth = (data) => {
    try {
      if (data?.value < startsortyearmonth?.value) {
        setStartSortYearMonth(data);
      }
      setEndSortYearMonth(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSortYearMonth = async () => {
    try {
      const resYearMonth = await axios.get("budget-sales/year-month");
      if (resYearMonth?.data?.length > 0) {
        let dataMock = [];
        // eslint-disable-next-line no-unused-expressions, array-callback-return
        await resYearMonth?.data?.map((itme) => {
          dataMock.push({
            value: itme,
            label: itme,
          });
        });
        setOptionSortYearMonth(dataMock);

        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const yearMonth = `${year}-${month}`;
        setStartSortYearMonth({
          value: yearMonth,
          label: yearMonth,
        });
        setEndSortYearMonth({
          value: yearMonth,
          label: yearMonth,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getBudgetSales = async () => {
    try {
      setLoading(true);
      const resSummary = await axios.get("budget-sales/all");
      if (resSummary?.data?.length > 0) {
        setDataMasterBudgetSales(resSummary?.data);
        setDataBudgetSales(resSummary?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getSortYearMonth();
    getBudgetSales();
  }, []);

  const sortDataAll = async () => {
    try {
      setLoading(true);
      let master_temp = [];
      if (datamasterbudgetsales?.length > 0) {
        master_temp = [...datamasterbudgetsales];
      }

      if (
        startsortyearmonth?.value !== null &&
        startsortyearmonth?.value !== undefined &&
        endsortyearmonth?.value !== null &&
        endsortyearmonth?.value !== undefined
      ) {
        let data_same = [];
        // eslint-disable-next-line no-unused-vars
        for await (const [_, item] of master_temp.entries()) {
          if (
            item?.year_and_month !== undefined &&
            item?.year_and_month !== null
          ) {
            // Extract year and month as separate values for easier comparison
            const itemDate = item.year_and_month.toLowerCase();
            const startDate = startsortyearmonth?.value.toLowerCase();
            const endDate = endsortyearmonth?.value.toLowerCase();

            if (itemDate >= startDate && itemDate <= endDate) {
              data_same.push(item);
            }
          }
        }
        master_temp = data_same;
      }

      if (
        datasearch !== null &&
        datasearch !== undefined &&
        datasearch !== ""
      ) {
        let data_same = [];
        // eslint-disable-next-line no-unused-vars
        for await (const [_, item] of master_temp.entries()) {
          if (item?.brand !== undefined && item?.brand !== null) {
            if (
              item?.brand.toLowerCase().includes(datasearch.toLowerCase()) > 0
            ) {
              data_same.push(item);
            }
          }

          if (item?.brand_code !== undefined && item?.brand_code !== null) {
            if (
              item?.brand_code
                .toLowerCase()
                .includes(datasearch.toLowerCase()) > 0
            ) {
              data_same.push(item);
            }
          }

          if (item?.brand_name !== undefined && item?.brand_name !== null) {
            if (
              item?.brand_name
                .toLowerCase()
                .includes(datasearch.toLowerCase()) > 0
            ) {
              data_same.push(item);
            }
          }

          if (
            item?.year_and_month !== undefined &&
            item?.year_and_month !== null
          ) {
            if (
              item?.year_and_month
                .toLowerCase()
                .includes(datasearch.toLowerCase()) > 0
            ) {
              data_same.push(item);
            }
          }

          if (item?.value !== undefined && item?.value !== null) {
            if (
              item?.value
                .toString()
                .toLowerCase()
                .includes(datasearch.toLowerCase()) > 0
            ) {
              data_same.push(item);
            }
          }
        }
        master_temp = data_same;
      }

      const uniqueData = master_temp.filter((obj, index, self) => {
        return (
          index ===
          self.findIndex(
            (item) => item?.id === obj?.id // Change 'name' to the desired property
          )
        );
      });
      setDataBudgetSales(uniqueData);
      setLoading(false);

      if (
        startsortyearmonth === null &&
        endsortyearmonth === null &&
        datasearch === ""
      ) {
        setDataBudgetSales(datamasterbudgetsales);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    sortDataAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datamasterbudgetsales, startsortyearmonth, endsortyearmonth, datasearch]);

  return (
    <div
      style={{
        paddingTop: 15,
        paddingBottom: 30,
      }}
    >
      <div className="container-budget-4">
        <div className="container-budget-6">
          <label style={{ marginRight: 10 }}>Month</label>
          <Select
            value={startsortyearmonth}
            styles={customstyles}
            options={optionsortyearmonth}
            onChange={changeStartYearMonth}
            isSearchable={true}
            placeholder="Start Year And Month"
            isClearable={startsortyearmonth?.value?.length > 0 ? true : false}
          />
        </div>
        <div className="container-budget-6">
          <label style={{ marginRight: 10 }}>To</label>
          <Select
            value={endsortyearmonth}
            styles={customstyles}
            options={optionsortyearmonth}
            onChange={changeEndYearMonth}
            isSearchable={true}
            placeholder="End Year And Month"
            isClearable={endsortyearmonth?.value?.length > 0 ? true : false}
          />
        </div>
        <div
          className="form-group"
          style={{ width: 250, marginRight: 10, marginBottom: 10 }}
        >
          <div className="input-icon input-icon-right">
            <input
              type="text"
              className="form-control"
              placeholder="Search..."
              value={datasearch}
              onChange={(e) => setDataSearch(e.target.value)}
            />
            <span>
              <i className="flaticon2-search-1 icon-md"></i>
            </span>
          </div>
        </div>
        {(userProfile === "98" || userProfile === "99") && (
          <>
            <input
              type="file"
              accept=".xlsx, .xls"
              onChange={handleFileUpload}
              style={{ display: "none" }}
              id="upload-button"
            />
            <button
              style={{ marginRight: 10 }}
              className="container-budget-10"
              onClick={() => document.getElementById("upload-button").click()}
              disabled={loadingimport}
            >
              Import
            </button>
            <button className="container-budget-13" onClick={clickExportFile}>
              Template File
            </button>
          </>
        )}
      </div>
      <MaterialTable
        title="Budget Sales"
        isLoading={loading}
        localization={{
          body: {
            emptyDataSourceMessage: "No Data",
          },
          toolbar: {
            searchPlaceholder: "Search...",
          },
          pagination: {
            labelRowsSelect: "Row",
            firstTooltip: "First",
            previousTooltip: "Previous",
            nextTooltip: "Next",
            lastTooltip: "Last",
          },
          header: {
            actions: "Action",
          },
        }}
        columns={[
          {
            title: "Year And Month",
            field: "year_and_month",
            render: (rowData) => {
              return (
                <div style={{ width: 100 }}>{rowData?.year_and_month}</div>
              );
            },
          },
          {
            title: "Brand",
            field: "brand",
            render: (rowData) => {
              return <div style={{ width: 100 }}>{rowData?.brand}</div>;
            },
          },
          {
            title: "Brand Code",
            field: "brand_code",
            render: (rowData) => {
              return <div style={{ width: 100 }}>{rowData?.brand_code}</div>;
            },
          },
          {
            title: "Brand Name",
            field: "brand_name",
            render: (rowData) => {
              return <div style={{ width: 200 }}>{rowData?.brand_name}</div>;
            },
          },
          {
            title: "Value",
            field: "value",
            render: (rowData) => {
              return (
                <div style={{ width: 120 }}>{addComma(rowData?.value)}</div>
              );
            },
          },
        ]}
        data={databudgetsales}
        options={tableOptions}
      />
    </div>
  );
};
