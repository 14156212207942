import React, { useState, useEffect } from "react";
import { useSubheader } from "../../_metronic/layout";
import MaterialTable from "material-table";
import axios from "axios";
import Select from "react-select";
import XLSX from "xlsx";

export const HomePage = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Home");

  const [loading, setLoading] = useState(false);
  const [datasummary, setDataSummary] = useState([]);
  const [datamastersummary, setDataMasterSummary] = useState([]);
  const [startsortyearmonth, setStartSortYearMonth] = useState({});
  const [endsortyearmonth, setEndSortYearMonth] = useState({});
  const [optionsortyearmonth, setOptionSortYearMonth] = useState([]);
  const [datasearch, setDataSearch] = useState("");
  const [typebudget, setTypeBudget] = useState({
    value: "EP",
    label: "Expense",
  });
  const [optionstypebudget, setOptionsTypeBudget] = useState([]);

  const tableOptions = {
    actionsColumnIndex: 8, // Set the index of the actions column
    toolbarButtonAlignment: "left", // Align the toolbar buttons to the left
    paging: true,
    pageSize: 20, // make initial page size
    emptyRowsWhenPaging: false, // To avoid of having empty rows
    pageSizeOptions: [20, 50, 100, 200], // rows selection options
    search: false,
    headerStyle: {
      zIndex: 0,
    },
  };

  const customstyles = {
    control: (provided, state) => ({
      ...provided,
      paddingLeft: 5,
      height: 35,
      width: 250,
      marginRight: 10,
      marginBottom: 10,
    }),
  };

  const addComma = (x) => {
    try {
      // ตรวจสอบว่าค่าที่ใส่เข้ามาเป็นตัวเลขหรือไม่
      if (isNaN(x)) {
        throw new Error("Input is not a valid number");
      }
      // แปลงค่าเป็นตัวเลขทศนิยม 2 ตำแหน่ง
      let number = parseFloat(x).toFixed(2);
      // แยกส่วนทศนิยมและใส่เครื่องหมายคอมม่า
      let parts = number.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
    } catch (error) {
      console.log(error);
    }
  };

  const getFormattedDateTime = () => {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, "0");
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = now.getFullYear();
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");
    return `${day}-${month}-${year}-${hours}${minutes}${seconds}`;
  };

  const clickExportFile = async () => {
    try {
      let data_new = [];
      // eslint-disable-next-line no-unused-vars
      for await (const [_, item] of datasummary.entries()) {
        data_new.push({
          account: item?.account,
          detail: item?.detail,
          type_name: item?.Type_name,
          department: item?.department,
          year_and_month: item?.year_and_month,
          request:
            item?.request !== "" && item?.request !== null
              ? parseFloat(item?.request).toFixed(2)
              : item?.request,
          budget:
            item?.budget !== "" && item?.budget !== null
              ? parseFloat(item?.budget).toFixed(2)
              : item?.budget,
          actual:
            item?.actual !== "" && item?.actual !== null
              ? parseFloat(item?.actual).toFixed(2)
              : item?.actual,
          diff:
            item?.diff !== "" && item?.diff !== null
              ? parseFloat(item?.diff).toFixed(2)
              : item?.diff,
        });
      }
      const dataWS = XLSX.utils.json_to_sheet(data_new, {
        header: [
          "account",
          "detail",
          "type_name",
          "department",
          "year_and_month",
          "request",
          "budget",
          "actual",
          "diff",
        ],
      });
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataWS, "Summary");
      XLSX.writeFile(wb, `summary_${getFormattedDateTime()}.xlsx`);
    } catch (error) {
      console.log(error);
    }
  };

  const changeStartYearMonth = (data) => {
    try {
      if (data?.value > endsortyearmonth?.value) {
        setEndSortYearMonth(data);
      }
      setStartSortYearMonth(data);
    } catch (error) {
      console.log(error);
    }
  };

  const changeEndYearMonth = (data) => {
    try {
      if (data?.value < startsortyearmonth?.value) {
        setStartSortYearMonth(data);
      }
      setEndSortYearMonth(data);
    } catch (error) {
      console.log(error);
    }
  };

  const changeTypeBudget = (data) => {
    try {
      setTypeBudget(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSortYearMonth = async () => {
    try {
      const resYearMonth = await axios.get("budget/year-month");
      if (resYearMonth?.data?.length > 0) {
        let dataMock = [];
        // eslint-disable-next-line no-unused-expressions, array-callback-return
        await resYearMonth?.data?.map((itme) => {
          dataMock.push({
            value: itme,
            label: itme,
          });
        });
        setOptionSortYearMonth(dataMock);

        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const yearMonth = `${year}-${month}`;
        setStartSortYearMonth({
          value: yearMonth,
          label: yearMonth,
        });
        setEndSortYearMonth({
          value: yearMonth,
          label: yearMonth,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSummary = async () => {
    try {
      setLoading(true);
      const resSummary = await axios.get("actual/summary");
      if (resSummary?.data?.length > 0) {
        setDataMasterSummary(resSummary?.data);
        setDataSummary(resSummary?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getTypeBudget = async () => {
    try {
      const resTypeBudget = await axios.get("budget/type");
      if (resTypeBudget?.data?.length > 0) {
        let dataMock = [];
        // eslint-disable-next-line no-unused-expressions, array-callback-return
        await resTypeBudget?.data?.map((itme) => {
          dataMock.push({
            value: itme?.Type_code,
            label: itme?.Type_name,
          });
        });
        setOptionsTypeBudget(dataMock);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTypeBudget();
    getSortYearMonth();
    getSummary();
  }, []);

  const sortDataAll = async () => {
    try {
      setLoading(true);
      let master_temp = [];
      if (datamastersummary?.length > 0) {
        master_temp = [...datamastersummary];
      }

      if (
        startsortyearmonth?.value !== null &&
        startsortyearmonth?.value !== undefined &&
        endsortyearmonth?.value !== null &&
        endsortyearmonth?.value !== undefined
      ) {
        let data_same = [];
        // eslint-disable-next-line no-unused-vars
        for await (const [_, item] of master_temp.entries()) {
          if (
            item?.year_and_month !== undefined &&
            item?.year_and_month !== null
          ) {
            // Extract year and month as separate values for easier comparison
            const itemDate = item.year_and_month.toLowerCase();
            const startDate = startsortyearmonth?.value.toLowerCase();
            const endDate = endsortyearmonth?.value.toLowerCase();

            if (itemDate >= startDate && itemDate <= endDate) {
              data_same.push(item);
            }
          }
        }
        master_temp = data_same;
      }

      if (typebudget?.value !== null && typebudget?.value !== undefined) {
        let data_same = [];
        // eslint-disable-next-line no-unused-vars
        for await (const [_, item] of master_temp.entries()) {
          if (item?.Type_name !== undefined && item?.Type_name !== null) {
            if (
              item?.Type_name.toLowerCase().includes(
                typebudget?.label.toLowerCase()
              ) > 0
            ) {
              data_same.push(item);
            }
          }
        }
        master_temp = data_same;
      }

      if (
        datasearch !== null &&
        datasearch !== undefined &&
        datasearch !== ""
      ) {
        let data_same = [];
        // eslint-disable-next-line no-unused-vars
        for await (const [_, item] of master_temp.entries()) {
          if (item?.account !== undefined && item?.account !== null) {
            if (
              item?.account.toLowerCase().includes(datasearch.toLowerCase()) > 0
            ) {
              data_same.push(item);
            }
          }

          if (item?.detail !== undefined && item?.detail !== null) {
            if (
              item?.detail.toLowerCase().includes(datasearch.toLowerCase()) > 0
            ) {
              data_same.push(item);
            }
          }

          if (item?.Type_name !== undefined && item?.Type_name !== null) {
            if (
              item?.Type_name.toLowerCase().includes(datasearch.toLowerCase()) >
              0
            ) {
              data_same.push(item);
            }
          }

          if (item?.department !== undefined && item?.department !== null) {
            if (
              item?.department
                .toLowerCase()
                .includes(datasearch.toLowerCase()) > 0
            ) {
              data_same.push(item);
            }
          }

          if (
            item?.year_and_month !== undefined &&
            item?.year_and_month !== null
          ) {
            if (
              item?.year_and_month
                .toLowerCase()
                .includes(datasearch.toLowerCase()) > 0
            ) {
              data_same.push(item);
            }
          }

          if (item?.request !== undefined && item?.request !== null) {
            if (
              item?.request
                .toString()
                .toLowerCase()
                .includes(datasearch.toLowerCase()) > 0
            ) {
              data_same.push(item);
            }
          }

          if (item?.budget !== undefined && item?.budget !== null) {
            if (
              item?.budget
                .toString()
                .toLowerCase()
                .includes(datasearch.toLowerCase()) > 0
            ) {
              data_same.push(item);
            }
          }

          if (item?.actual !== undefined && item?.actual !== null) {
            if (
              item?.actual
                .toString()
                .toLowerCase()
                .includes(datasearch.toLowerCase()) > 0
            ) {
              data_same.push(item);
            }
          }

          if (item?.diff !== undefined && item?.diff !== null) {
            if (
              item?.diff
                .toString()
                .toLowerCase()
                .includes(datasearch.toLowerCase()) > 0
            ) {
              data_same.push(item);
            }
          }
        }
        master_temp = data_same;
      }

      const uniqueData = master_temp.filter((obj, index, self) => {
        return (
          index ===
          self.findIndex(
            (item) =>
              item?.account === obj?.account &&
              item?.department === obj?.department &&
              item?.year_and_month === obj?.year_and_month // Change 'name' to the desired property
          )
        );
      });
      setDataSummary(uniqueData);
      setLoading(false);

      if (
        startsortyearmonth === null &&
        endsortyearmonth === null &&
        datasearch === "" &&
        typebudget?.value === undefined
      ) {
        setDataSummary(datamastersummary);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    sortDataAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    datamastersummary,
    startsortyearmonth,
    endsortyearmonth,
    typebudget,
    datasearch,
  ]);

  return (
    <div
      style={{
        paddingTop: 15,
        paddingBottom: 30,
      }}
    >
      <div className="container-budget-4">
        <div className="container-budget-6">
          <label style={{ marginRight: 10 }}>Month</label>
          <Select
            value={startsortyearmonth}
            styles={customstyles}
            options={optionsortyearmonth}
            onChange={changeStartYearMonth}
            isSearchable={true}
            placeholder="Start Year And Month"
            isClearable={startsortyearmonth?.value?.length > 0 ? true : false}
          />
        </div>
        <div className="container-budget-6">
          <label style={{ marginRight: 10 }}>To</label>
          <Select
            value={endsortyearmonth}
            styles={customstyles}
            options={optionsortyearmonth}
            onChange={changeEndYearMonth}
            isSearchable={true}
            placeholder="End Year And Month"
            isClearable={endsortyearmonth?.value?.length > 0 ? true : false}
          />
        </div>
        <div className="container-budget-6">
          <label htmlFor="Type" style={{ marginRight: 10 }}>
            Type
          </label>
          <Select
            value={typebudget}
            styles={customstyles}
            options={optionstypebudget}
            onChange={changeTypeBudget}
            isSearchable={true}
            placeholder="Type Budget"
            isClearable={typebudget?.value?.length > 0 ? true : false}
          />
        </div>
        <div
          className="form-group"
          style={{ width: 250, marginRight: 10, marginBottom: 10 }}
        >
          <div className="input-icon input-icon-right">
            <input
              type="text"
              className="form-control"
              placeholder="Search..."
              value={datasearch}
              onChange={(e) => setDataSearch(e.target.value)}
            />
            <span>
              <i className="flaticon2-search-1 icon-md"></i>
            </span>
          </div>
        </div>
        <button
          className="container-budget-5"
          onClick={clickExportFile}
          disabled={datasummary?.length > 0 ? false : true}
        >
          Export
        </button>
      </div>
      <MaterialTable
        title="Summary"
        isLoading={loading}
        localization={{
          body: {
            emptyDataSourceMessage: "No Data",
          },
          toolbar: {
            searchPlaceholder: "Search...",
          },
          pagination: {
            labelRowsSelect: "Row",
            firstTooltip: "First",
            previousTooltip: "Previous",
            nextTooltip: "Next",
            lastTooltip: "Last",
          },
          header: {
            actions: "Action",
          },
        }}
        columns={[
          {
            title: "Account",
            field: "account",
            render: (rowData) => {
              return <div style={{ width: 100 }}>{rowData?.account}</div>;
            },
          },
          {
            title: "Detail",
            field: "detail",
            render: (rowData) => {
              return <div style={{ width: 250 }}>{rowData?.detail}</div>;
            },
          },
          {
            title: "Type Budget",
            field: "Type_name",
            render: (rowData) => {
              return <div style={{ width: 100 }}>{rowData?.Type_name}</div>;
            },
          },
          {
            title: "Department",
            field: "department",
            render: (rowData) => {
              return <div style={{ width: 120 }}>{rowData?.department}</div>;
            },
          },
          {
            title: "Year And Month",
            field: "year_and_month",
            render: (rowData) => {
              return (
                <div style={{ width: 120 }}>{rowData?.year_and_month}</div>
              );
            },
          },
          {
            title: "Request",
            field: "request",
            render: (rowData) => {
              return (
                <div style={{ width: 120 }}>{addComma(rowData?.request)}</div>
              );
            },
          },
          {
            title: "Budget",
            field: "budget",
            render: (rowData) => {
              return (
                <div style={{ width: 120 }}>{addComma(rowData?.budget)}</div>
              );
            },
          },
          {
            title: "Actual",
            field: "actual",
            render: (rowData) => {
              return (
                <div style={{ width: 120 }}>{addComma(rowData?.actual)}</div>
              );
            },
          },
          {
            title: "Diff",
            field: "diff",
            render: (rowData) => {
              return (
                <div style={{ width: 120 }}>{addComma(rowData?.diff)}</div>
              );
            },
          },
        ]}
        data={datasummary}
        options={tableOptions}
      />
    </div>
  );
};
